import { render, staticRenderFns } from "./select-addr.vue?vue&type=template&id=50a39a95&scoped=true&"
import script from "./select-addr.vue?vue&type=script&lang=js&"
export * from "./select-addr.vue?vue&type=script&lang=js&"
import style0 from "./select-addr.vue?vue&type=style&index=0&id=50a39a95&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a39a95",
  null
  
)

export default component.exports