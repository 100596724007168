var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-dialog',{attrs:{"beforeClose":_vm.beforeClose,"title":"其他地址","show-cancel-button":"","confirm-button-text":"确定","confirm-button-color":"#1F7FFF"},on:{"confirm":_vm.confirm,"cancel":_vm.cancel},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',{staticClass:"addr-list"},[_c('van-radio-group',{on:{"change":_vm.changeRadio},model:{value:(_vm.checkCode),callback:function ($$v) {_vm.checkCode=$$v},expression:"checkCode"}},[(_vm.type === 'pass')?_c('div',{staticClass:"pass-box"},_vm._l((_vm.list),function(item){return _c('van-radio',{key:item.userZoneRelationPO.id,staticClass:"addr-radio",attrs:{"name":item.userZoneRelationPO.id}},[(item.zonePO.siteType == 0)?_c('span',{class:{
              'check-label': _vm.checkCode === item.userZoneRelationPO.id,
              'radio-label': true,
            }},[_vm._v(" "+_vm._s(item.zonePO.address)+_vm._s(item.zonePO.name)+_vm._s(item.userZoneRelationPO.ridgepole)+"栋(排)"+_vm._s(item.userZoneRelationPO.unit)+"单元(号)"+_vm._s(item.userZoneRelationPO.chamber)+"室 ")]):_vm._e(),(
              item.zonePO.siteType == 2 ||
              item.zonePO.siteType == 1 ||
              item.zonePO.siteType == 3
            )?_c('span',{class:{
              'check-label': _vm.checkCode === item.code,
              'radio-label': true,
            }},[_vm._v(" "+_vm._s(item.zonePO.address)+_vm._s(item.zonePO.name)+" ")]):_vm._e()])}),1):_vm._e(),(_vm.type === 'visit')?_c('div',{staticClass:"visit-box"},_vm._l((_vm.list),function(item){return _c('van-radio',{key:item.visitorRecordPO.id,staticClass:"addr-radio",attrs:{"name":item.visitorRecordPO.id}},[_c('div',{staticClass:"label-name"},[_vm._v(" 被访问人姓名："+_vm._s(item.userZoneRelationPO.name)+" ")]),(item.zonePO.siteType == 0)?_c('span',{class:{ 'radio-label': true }},[_vm._v(" "+_vm._s(item.zonePO.address)+_vm._s(item.zonePO.name)+_vm._s(item.userZoneRelationPO.ridgepole)+"栋(排)"+_vm._s(item.userZoneRelationPO.unit)+"单元(号)"+_vm._s(item.userZoneRelationPO.chamber)+"室 ")]):_vm._e(),(item.zonePO.siteType == 2 || item.zonePO.siteType == 3)?_c('span',{class:{ 'radio-label': true }},[_vm._v(" "+_vm._s(item.zonePO.address)+_vm._s(item.zonePO.name)+" ")]):_vm._e()])}),1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }