<template>
  <van-dialog
    v-model="isShow"
    :beforeClose="beforeClose"
    title="其他地址"
    show-cancel-button
    confirm-button-text="确定"
    confirm-button-color="#1F7FFF"
    @confirm="confirm"
    @cancel="cancel"
  >
    <div class="addr-list">
      <van-radio-group v-model="checkCode" @change="changeRadio">
        <div v-if="type === 'pass'" class="pass-box">
          <van-radio
            class="addr-radio"
            v-for="item in list"
            :key="item.userZoneRelationPO.id"
            :name="item.userZoneRelationPO.id"
          >
            <span
              v-if="item.zonePO.siteType == 0"
              :class="{
                'check-label': checkCode === item.userZoneRelationPO.id,
                'radio-label': true,
              }"
            >
              {{ item.zonePO.address }}{{ item.zonePO.name
              }}{{ item.userZoneRelationPO.ridgepole }}栋(排){{
                item.userZoneRelationPO.unit
              }}单元(号){{ item.userZoneRelationPO.chamber }}室
            </span>
            <span
              v-if="
                item.zonePO.siteType == 2 ||
                item.zonePO.siteType == 1 ||
                item.zonePO.siteType == 3
              "
              :class="{
                'check-label': checkCode === item.code,
                'radio-label': true,
              }"
            >
              {{ item.zonePO.address }}{{ item.zonePO.name }}
            </span>
          </van-radio>
        </div>
        <div v-if="type === 'visit'" class="visit-box">
          <van-radio
            class="addr-radio"
            v-for="item in list"
            :key="item.visitorRecordPO.id"
            :name="item.visitorRecordPO.id"
          >
            <div class="label-name">
              被访问人姓名：{{ item.userZoneRelationPO.name }}
            </div>
            <span
              v-if="item.zonePO.siteType == 0"
              :class="{ 'radio-label': true }"
            >
              {{ item.zonePO.address }}{{ item.zonePO.name
              }}{{ item.userZoneRelationPO.ridgepole }}栋(排){{
                item.userZoneRelationPO.unit
              }}单元(号){{ item.userZoneRelationPO.chamber }}室
            </span>
            <span
              v-if="item.zonePO.siteType == 2 || item.zonePO.siteType == 3"
              :class="{ 'radio-label': true }"
            >
              {{ item.zonePO.address }}{{ item.zonePO.name }}
            </span>
          </van-radio>
        </div>
      </van-radio-group>
    </div>
  </van-dialog>
</template>
<script>
export default {
  props: ["isShow", "list", "code", "type"],
  data() {
    return {
      checkCode: ""
    };
  },
  watch: {
    code(val) {
      this.checkCode = val;
    }
  },
  mounted() {
    this.checkCode = this.code;
  },
  methods: {
    confirm() {
      console.log("confirm", this.checkCode);
      this.$emit("confirm", this.checkCode);
    },
    cancel() {
      this.$emit("cancel");
    },
    beforeClose(action, done) {
      done(false);
    },
    changeRadio(val) { }
  }
};
</script>
<style lang="less" scoped>
.addr-list {
  max-height: 11.733333rem /* 220/18.75 */;
  overflow-y: auto;
  padding: 1.066667rem /* 20/18.75 */ 0.8rem /* 15/18.75 */ 1.066667rem
    /* 20/18.75 */ 1.066667rem /* 20/18.75 */;
  .addr-radio {
    height: 3.2rem /* 60/18.75 */;
  }
  .visit-box {
    .addr-radio {
      height: 4.8rem /* 90/18.75 */;
    }
  }
  .label-name {
    font-size: 0.8rem /* 15/18.75 */;
  }
  .radio-label {
    color: #aaa;
    font-size: 0.8rem /* 15/18.75 */;
  }
  .check-label {
    color: #333;
  }
}
</style>
