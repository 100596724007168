<template>
  <div class="visit-invited">
    <van-form ref="vanForm" @submit="onSubmit" label-width="4.266667rem">
      <div class="box" style="padding-top: 0;">
        <div class="code-nav">
          <div v-for="item in navList"
              :key="item.value"
              :class="{'active': item.value === navCode}"
              @click="clickNav(item)">{{item.name}}</div>
        </div>
        <div>
          <van-field
            v-model="formData.name"
            required
            :rules="rules.name"
            maxlength="32"
            name="name"
            label="姓名"
            placeholder="请输入姓名"></van-field>
          <van-field
            v-model="formData.phone"
            required
            :rules="rules.phone"
            maxlength="11"
            name="phone"
            type="number"
            label="手机号码"
            placeholder="请输入手机号码"></van-field>
        </div>
      </div>
      <div class="box">
        <van-field right-icon="arrow"
                   required
                   readonly
                   clickable
                   label="省市区"
                   v-if="!zoneId"
                   v-model="region"
                   :rules="[{ required: true, message: '请选择省市区' }]"
                   placeholder="点击选择省市区"
                   @click="showAreaPicker = true" />
        <div class="field-box">
          <div class="label-width">小区/单位</div>
          <div class="my my-popover">
            <van-popover  v-model="showZone"
                          trigger="click"
                          @select="onZoneConfirm"
                          :actions="actions"
                          :offset="[20, 0]"
                          get-container=".field-box">
              <template #reference>
                <input  type="text"
                        @input="formData.zoneId = null"
                        :disabled="zoneId !== ''"
                        v-model="zoneName"
                        placeholder="请选择小区/单位">
              </template>
            </van-popover>
          </div>
          <van-icon name="arrow"/>
        </div>
        <div class="field-box" v-if="showAddress">
          <div class="label-width">详细地址</div>
          <input
            readonly
            v-model="formData.ridgepole"
            @click="showRidgepole=true"/>栋
          <input
            readonly
            v-model="formData.unit"
            @click="showUnit=true"/>单元
          <input
            v-model="formData.chamber"/>室
          <van-popup v-model="showRidgepole" round position="bottom">
            <van-picker
              title="栋"
              show-toolbar
              :columns="ridgepoleColumns"
              @confirm="onRidgepoleConfirm"
              @cancel="showRidgepole=false"
            />
          </van-popup>
          <van-popup v-model="showUnit" round position="bottom">
            <van-picker
              title="单元"
              show-toolbar
              :columns="unitColumns"
              @confirm="onUnitConfirm"
              @cancel="showUnit=false"
            />
          </van-popup>
        </div>
        <van-field
          v-model="formData.toUserName"
          required
          :rules="rules.toUserName"
          maxlength="32"
          name="toUserName"
          label="被访人"
          placeholder="请输入被访人姓名"></van-field>
        <van-field
          v-model="formData.toUserPhone"
          required
          :rules="rules.toUserPhone"
          maxlength="11"
          name="toUserPhone"
          type="number"
          label="手机号码"
          placeholder="请输入手机号码"></van-field>
        <van-field
          v-model="formData.reason"
          required
          readonly
          clickable
          :rules="rules.reason"
          name="reason"
          label="访问事由"
          placeholder="请选择访问事由"
          right-icon="arrow"
          @click="showReason=true"></van-field>
        <van-popup v-model="showReason" round position="bottom">
          <van-picker
            title="访问事由"
            show-toolbar
            :columns="reasonColumns"
            @confirm="onReasonConfirm"
            @cancel="showReason=false"
          />
        </van-popup>
      </div>
      <div class="box" v-if="isShowMore">
        <van-field
          v-model="carNumber"
          maxlength="8"
          name="车牌号"
          label="车牌号"
          placeholder="请输入车牌号"></van-field>
      </div>
      <div
        class="box"
        style="padding-bottom: 0;"
        v-if="isShowMore">
        <div>
          <div
            class="people-item"
            v-for="(item, index) in peopleList"
            :key="index">
            <van-field
              v-model="item.friendName"
              maxlength="32"
              name="同行人姓名"
              label="同行人姓名"
              placeholder="请输入同行人姓名"></van-field>
            <van-field
              v-model="item.friendPhone"
              :rules="rules.friendPhone"
              maxlength="11"
              type="number"
              name="手机号码"
              label="手机号码"
              placeholder="请输入手机号码"></van-field>
          </div>
        </div>
        <van-button
          class="add-btn"
          block
          type="info"
          native-type="button"
          @click="addPeople()">添加同行人员信息({{peopleList.length}}/5)</van-button>
      </div>
      <div class="more-click" v-if="!isShowMore" @click="isShowMore=true">补充更多信息</div>
      <van-popup v-model="showAreaPicker"
                 round
                 position="bottom">
        <van-area title="省市区"
                  :area-list="areaList"
                  :value="areaCode"
                  @confirm="onAreaConfirm"
                  @cancel="showAreaPicker = false" />
      </van-popup>
      <div class="btn-box">
        <van-button
          class="sub-btn"
          block
          type="info"
          :disabled="disabled"
          native-type="submit">提交申请</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getZone, getCanUseZone, createVisitor, getAreaZone } from "../../utils/codeRequest";
import api from '@api';
import {RulePhone} from '../../utils/rules';
export default {
  props: ['navCode', 'user'],
  data() {
    return{
      navList: [
        {
          name: '访客码',
          value: 'visit'
        }, {
          name: '通行码',
          value: 'pass'
        }
      ],
      showZone: false,
      zoneColumns: [],
      selectedZone: null,
      showAddress: true,
      showRidgepole: false,
      ridgepoleColumns: [],
      showUnit: false,
      unitColumns: [],
      showReason: false,
      reasonColumns: ['会议', '接送', '上课', '拿资料', '谈事情', '业务', '看望', '办事'],
      showAreaPicker: false,
      areaList: { // 省市区列表
        province_list: {
        },
        city_list: {
        },
        county_list: {
        }
      },
      areaCode: '330104',
      region: '浙江省/杭州市/江干区',
      zoneName: '',
      zoneId: '',
      peopleList: [{friendName: '', friendPhone: ''}],
      carList: [],
      carNumber: '',
      formData: {
        name: '',
        phone: '',
        zoneId: '',
        ridgepole: '', // 栋
        unit: '', // 单元
        chamber: '', // 室
        reason: '',
        toUserName: '',
        toUserPhone: ''
      },
      rules: {
        name: [
          {required: true, message: '姓名不能为空'}
        ],
        phone: [
          {validator: RulePhone, message: '手机号码不正确'},
          {required: true, message: '手机号码不能为空'}
        ],
        // zoneName: [
        //   {required: true, message: '小区不能为空'}
        // ],
        toUserName: [
          {required: true, message: '被访人不能为空'}
        ],
        toUserPhone: [
          {validator: RulePhone, message: '手机号码不正确'},
          {required: true, message: '被访人手机不能为空'}
        ],
        reason: [
          {required: true, message: '访问事由不能为空'}
        ],
        friendPhone: [
          {validator: RulePhone, message: '手机号码不正确'}
        ]
      },
      isShowMore: false,
      isSelectAction: false,
    }
  },
  watch: {
    user() {
      this.initData();
    },
    selectedZone(val) {
      if (val) {
        this.showAddress = val.type == 0;
        this.formData.zoneId = val.id;
        this.zoneName = val.text;
        switch(val.type) {
          case 0:
            this.unitColumns = this.$tools.getArray(parseInt(val.unit));
            this.ridgepoleColumns = this.$tools.getArray(parseInt(val.building));
            break;
          case 1:
            break;
          case 2:
            break;
        }
      } else {
        this.showAddress = true;
        this.unitColumns = [];
        this.ridgepoleColumns = [];
      }
    }
  },
  computed: {
    actions: {
      get () {
        let actions = []
        if (this.zoneColumns.length && this.zoneName) {
          actions = this.zoneColumns.filter(zone => {
            return zone.text.indexOf(this.zoneName) !== -1
          })
        } else {
          actions = this.zoneColumns
        }
        return actions
      },
      set (v) {
      }
    },
    disabled () {
      let requireds = ['name', 'phone', 'zoneId', 'toUserName', 'toUserPhone', 'reason']
      let disabled = false
      requireds.forEach(required => {
        if (!this.formData[required]) {
          disabled = true
        }
      })
      if (this.showAddress && ( !this.formData.ridgepole || !this.formData.unit || !this.formData.chamber )) {
        disabled = true
      }
      return disabled
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.formData.name = this.user.userName;
      this.formData.phone = this.user.phone;
      this.getAreaList();
      if (this.$route.query.zoneId) {
        this.zoneId = this.$route.query.zoneId;
        this.getZone(this.$route.query.zoneId)
      } else {
        this.getZoneByArea(this.areaCode);
        // this.getZoneList();
      }
    },
    // 获取行政区划的列表
    getAreaList () {
      api.getAreaList().then(res => {
        res.data.forEach(province => {
          this.areaList.province_list[province.provinceCode] = province.provinceName
          province.cityList.forEach(city => {
            this.areaList.city_list[city.cityCode] = city.cityName
            city.areaList.forEach(area => {
              this.areaList.county_list[area.areaCode] = area.areaName
            })
          })
        })
      })
    },
    // 选择省市区
    onAreaConfirm(data) {
      console.log(data)
      this.region = data[0].name + '/' + data[1].name + '/' + data[2].name;
      this.getZoneByArea(data[2].code);
      this.showAreaPicker = false;
    },
    // 获取扫码进来的小区或单位信息
    getZone: function(zoneId) {
      getZone({zoneId: zoneId}).then(res => {
        if (res.code === 'SUCCESS') {
          let data = res.data
          var newItem = {
          	region: data.region,
          	street: data.street,
          	community: data.community,
          	text: data.name,
          	id: data.id,
          	type: data.type,
          	unit: data.unit,
          	building: data.building
          };
          this.zoneColumns = [newItem];
          this.selectedZone = newItem;
        } else {
        }
      })
    },
    // 根据area获取小区/单位列表
    getZoneByArea(areaCode) {
      getAreaZone({areaCodes: areaCode}).then(res => {
        if (res.code === 'SUCCESS') {
          var tempItems = [];
					for (var i = 0; i < res.data.length; i++) {
            var item = res.data[i];
            var newItem = {
              region: item.region,
              street: item.street,
              community: item.community,
              text: item.name,
              id: item.id,
              type: item.type,
              unit: item.unit,
              building: item.building
            };
            tempItems.push(newItem);
					}
					this.zoneColumns = tempItems;
        }
      })
    },
    // 获取小区/单位下拉列表(弃用)
    getZoneList() {
      getCanUseZone().then(res => {
        if (res.code === 'SUCCESS') {
          var tempItems = [];
					for (var i = 0; i < res.data.length; i++) {
						var item = res.data[i];
						if (item.zonePO) {
							var newItem = {
								region: item.zonePO.address,
								street: item.zonePO.street,
								community: item.zonePO.community,
								text: item.zonePO.name,
								id: item.zonePO.id,
								type: item.zonePO.siteType,
								unit: item.zonePO.unit,
								building: item.zonePO.building
							};
							tempItems.push(newItem);
						}
					}
					this.zoneColumns = tempItems;
        }
      })
    },
    /** 
     * 选择nav
     */
    clickNav(item) {
      this.$emit('changeNav', item);
    },
    addPeople(){
      if (this.peopleList.length < 5) {
        this.peopleList.push({
          friendName: '',
          friendPhone: ''
        })
      }
    },
    /** 
     * 小区选择
     */
    onZoneConfirm(data) {
      if (data) {
        this.zoneName = data.text;
        this.formData.zoneId = data.id;
        this.selectedZone = data;
      }
      this.showZone = false;
    },
    /**
     * 栋
     */
    onRidgepoleConfirm(data) {
      this.formData.ridgepole = data;
      this.showRidgepole = false;
    },
    /**
     * 单元
     */
    onUnitConfirm(data) {
      this.formData.unit = data;
      this.showUnit = false;
    },
    /**
     * 访问理由选择
     */
    onReasonConfirm(data){
      this.formData.reason = data;
      this.showReason = false;
    },
    // 提交
    onSubmit(data) {
      let sendData = JSON.parse(JSON.stringify(this.formData));
      if (!this.formData.zoneId) {
        this.$toast('请选择小区/单位')
        return
      }
      if (this.showAddress) {
        if (!this.formData.ridgepole || !this.formData.unit || !this.formData.chamber) {
          this.$toast('请填写详细地址')
          return
        }
      }
      sendData.hasDriver = this.isShowMore ? 1 : 0;
      sendData.hasFriend = this.isShowMore ? 1 : 0;
      if (this.isShowMore) {
        sendData.carNumbers = [];
        sendData.friends = []
        if (this.carNumber) {
          sendData.carNumbers = [this.carNumber]
        }
        this.peopleList.forEach(item => {
          if (item.friendName || item.friendPhone) {
            sendData.friends.push(item)
          }
        })
      }
      console.log('sendData', sendData)
      createVisitor(sendData).then(res => {
        if (res.code === 'SUCCESS') {
          this.$toast.success('提交成功！')
          this.$globalFun.setUrlHeader("/pages/home/mine/visit-history") // 跳转访问记录页面
        } else {
          this.$toast.fail(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .visit-invited{
    position: absolute;
    top: 6.613333rem /* 124/18.75 */;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    font-size: .8rem /* 15/18.75 */;
    /deep/ .van-cell {
      font-size: .8rem /* 15/18.75 */;
      line-height: 2.08rem /* 39/18.75 */;
    }
    /deep/ .van-form{
      width: 18.133333rem /* 340/18.75 */;
      margin: 0 auto;
      padding-bottom: 5.333333rem /* 100/18.75 */;
    }
    .field-box{
      position: relative;
      display: flex;
      align-items: center;
      color: #999;
      height: 2.08rem /* 39/18.75 */;
      line-height: 2.08rem /* 39/18.75 */;
      padding: .533333rem /* 10/18.75 */ .853333rem /* 16/18.75 */;
      box-sizing: content-box;
      // overflow: hidden;
      &::before{
        position: absolute;
        left: .426667rem /* 8/18.75 */;
        color: #ee0a24;
        font-size: 14px;
        content: '*';
      }
      &::after{
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
      }
      .label-width{
        color: #646566;
        width: 4.266667rem /* 80/18.75 */;
        flex-shrink: 0;
        margin-right: .64rem /* 12/18.75 */;
      }
      input{
        border: none;
        width: 2.4rem /* 45/18.75 */;
        height: 2.08rem /* 39/18.75 */;
        // padding: 0 ;
        margin-left: .266667rem /* 5/18.75 */;
        color: #323233;
      }
      
      /deep/.van-popover__action {
        width: 10.666667rem /* 200/18.75 */;
      }
      /deep/ .van-popover__content{
        max-height: 9.6rem /* 180/18.75 */;
        overflow-y: auto;
      }
    }
    .my {
      flex: 1;
      &.my-popover {
        .van-popover__wrapper {
          width: 100%;
          input {
            width: 100% !important;
            text-align: left;
            &::-webkit-input-placeholder{
              color: #ccc;
            }
            &:disabled{
              background: #fff;
            }
          }
        }
      }
    }
    .box{
      background: #fff;
      border-radius: .8rem /* 15/18.75 */;
      box-shadow: .106667rem /* 2/18.75 */ 0 .533333rem /* 10/18.75 */ rgba(4, 0, 0, .1);
      margin-bottom: .533333rem /* 10/18.75 */;
      padding: .533333rem /* 10/18.75 */ 0;
      .code-nav{
        display: flex;
        text-align: center;
        font-size: .96rem /* 18/18.75 */;
        height: 3.306667rem /* 62/18.75 */;
        line-height: 3.306667rem /* 62/18.75 */;
        border-bottom: .026667rem /* 0.5/18.75 */ solid #ebebeb;
        div{
          position: relative;
          flex: 1;
          color: #999;
          &:first-child::after{
            content: '';
            position: absolute;
            width: .026667rem /* 0.5/18.75 */;
            height: 1.146667rem /* 21.5/18.75 */;
            background: #ebebeb;
            right: 0;
            top: 1.066667rem /* 20/18.75 */;
          }
          &.active{
            color: #2B71FD;
          }
        }
      }
      .people-item{
        /deep/ .van-cell:last-child::after{
          display: block;
        }
        
      }
      .people-item:nth-last-child(1){
        /deep/ .van-cell:last-child::after{
          display: none !important;
        }
      }
    }
    .add-btn{
      border-radius: 0 0 .8rem /* 15/18.75 */ .8rem /* 15/18.75 */;
      margin-top: .533333rem /* 10/18.75 */;
    }
    .more-click{
      text-align: center;
      color: #2B71FD;
      margin-top: .8rem /* 15/18.75 */;
    }
    .btn-box{
      position: fixed;
      width: 100%;
      bottom: 0;
      padding: 1.066667rem /* 20/18.75 */ 0 1.6rem /* 30/18.75 */;
      background: #fff;

    }
    .sub-btn{
      width: 18.133333rem /* 340/18.75 */;
      border-radius: .346667rem /* 6.5/18.75 */;
      font-size: .96rem /* 18/18.75 */;
    }
  }
</style>