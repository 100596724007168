<template>
  <div :class="`code ${codeColor}`">
    <div
      :class="{
        person: true,
        'person-mt': !(!isInvited && navCode === 'visit'),
      }"
    >
      <img class="avatar" :src="user.avatarImage" />
      <div>
        <div
          :class="{ name: true, 'name-mt': isInvited && navCode === 'visit' }"
        >
          {{ user.userName }}
        </div>
        <div
          :class="{
            addr: true,
            'addr-mt': !(!isInvited && navCode === 'visit'),
          }"
          @click="showAddr"
        >
          <img
            v-if="!(isInvited && navCode === 'visit')"
            :src="`${publickPath}static/img/code/icon-postion.png`"
          />
          <div class="addr-name" v-if="navCode === 'pass'">
            {{ myAddrText }}
          </div>
          <div
            class="addr-name"
            v-if="!(isInvited && navCode === 'visit') && navCode !== 'pass'"
          >
            {{ visitAddrText }}
          </div>
          <van-icon
            v-if="!(isInvited && navCode === 'visit')"
            class="arrow"
            name="arrow"
          />
        </div>
        <div class="tag-box" v-if="!isInvited && navCode === 'visit'">
          <div class="tag">{{ codeData.userZoneRelationPO.name }}(被访人)</div>
          <div class="tag" v-if="codeData.visitorFriendPOList">
            同行人员{{
              codeData.visitorFriendPOList
                ? codeData.visitorFriendPOList.length
                : 0
            }}人
          </div>
        </div>
      </div>
    </div>
    <div class="box" v-show="!(isInvited && navCode === 'visit')">
      <div class="code-nav">
        <div
          v-for="item in navList"
          :key="item.value"
          :class="{ active: item.value === navCode }"
          @click="clickNav(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        class="code-content"
        v-show="!(navCode === 'pass' && myAddrList.length === 0)"
      >
        <div class="time">{{ this.currentTime }}</div>
        <div ref="qrcode" class="code-img" @click="showBigCode = true"></div>
        <div class="hide" v-if="codeColor != 'gray'">
          已对接健康码平台，凭此二维码可正常出入请主动出示并配合检查。
        </div>
        <div class="hide" v-if="codeColor == 'gray'">健康码获取失败</div>
      </div>
      <div
        class="none-code"
        v-show="navCode === 'pass' && myAddrList.length === 0"
      >
        <div class="pend-check" v-if="noCheckAddr.length > 0">
          <img :src="`${publickPath}static/img/code/pending-check.png`" />
          <div>申请已提交，加急审核中...</div>
        </div>
        <!-- <div class="go-real" v-if="noCheckAddr.length === 0">
          <van-button type="info" @click="goRealName">请前往实名认证</van-button>
        </div> -->
      </div>
    </div>
    <visit-apply
      v-show="isInvited && navCode === 'visit'"
      :navCode="navCode"
      :user="user"
      @changeNav="clickNav"
    ></visit-apply>
    <!-- 地址选择框 -->
    <select-addr
      :isShow="showMyAddrDialog"
      :list="myAddrList"
      :code="myAddrCode"
      type="pass"
      @cancel="showMyAddrDialog = false"
      @confirm="confirmMyAddr"
    >
    </select-addr>
    <select-addr
      :isShow="showVisitAddrDialog"
      :list="visitAddrList"
      :code="visitAddrCode"
      type="visit"
      @cancel="showVisitAddrDialog = false"
      @confirm="confirmVisitAddr"
    >
    </select-addr>
    <div class="modle" v-show="showBigCode" @click="showBigCode = false">
      <div class="big-code-box">
        <div ref="bigQrcode" class="big-code-img"></div>
      </div>
    </div>
    <toast-modal ref="tostModal" @cancelZ="cancelZ"></toast-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import md5 from "../../utils/md5";
import { Dialog } from "vant";
import VisitApply from "./visit-apply.vue";
import SelectAddr from "./select-addr";
import toastModal from "@/components/toastModal";
import {
  getUserInfo,
  getJkm,
  getVisitList,
  getLocationAddr
} from "../../utils/codeRequest";
import axios from "axios";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    VisitApply,
    SelectAddr,
    toastModal
  },
  data() {
    return {
      toastVisible: false,
      publickPath: process.env.BASE_URL,
      user: {},
      codeColor: "gray",
      colorList: {
        green: "#27AD64",
        red: "#DE4040",
        orange: "#DB7E23",
        gray: "#333"
      },
      navList: [
        {
          name: "访客码",
          value: "visit"
        },
        {
          name: "通行码",
          value: "pass"
        }
      ],
      navCode: this.$route.query.type || "visit",
      timer: null,
      currentTime: this.$tools.formatDate(new Date(), "yyyy-MM-dd hh:mm:ss"),
      isInvited: true,
      showMyAddrDialog: false,
      showBigCode: false,
      myAddrCode: "",
      myAddrText: "",
      selectMyAddr: {}, // 选中的通行码
      myAddrList: [], //通行码地址列表
      noCheckAddr: [], // 等待审核的通行码
      showVisitAddrDialog: false,
      visitAddrCode: "",
      visitAddrText: "",
      selectVisitAddr: {}, // 选中的访客码
      visitAddrList: [], // 访客码地址列表
      codeData: {}, // 选中地址需要生成二维码的数据
      jkmStr: "", // 健康码的值
      zoneId: this.$route.query.zoneId || "", // 地址栏带的参数
      visitId: this.$route.query.id || "" // 通过访问记录列表进入的访客码的
    };
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["returnRoleData"])
  },
  watch: {
    selectMyAddr(val) {
      // 通行码地址切换
      if (val) {
        this.myAddrCode = val.userZoneRelationPO.id;
        if (val.zonePO.siteType == 0) {
          this.myAddrText = `${val.zonePO.address}${val.zonePO.name}
            ${val.userZoneRelationPO.ridgepole}栋(排)${val.userZoneRelationPO.unit}单元(号)${val.userZoneRelationPO.chamber}室`;
        } else {
          this.myAddrText = `${val.zonePO.address}${val.zonePO.name}`;
        }
        if (this.navCode === "pass") {
          this.codeData = val;
          if (this.myAddrList.length !== 0) {
            this.setColor();
            this.createQrcode();
          }
        }
      }
    },
    selectVisitAddr(val) {
      // 访客码地址切换
      if (val) {
        this.visitAddrCode = val.visitorRecordPO.id;
        if (val.zonePO.siteType == 0) {
          this.visitAddrText = `${val.zonePO.address}${val.zonePO.name}
            ${val.userZoneRelationPO.ridgepole}栋(排)${val.userZoneRelationPO.unit}单元(号)${val.userZoneRelationPO.chamber}室`;
        } else {
          this.visitAddrText = `${val.zonePO.address}${val.zonePO.name}`;
        }
        if (this.navCode === "visit") {
          this.codeData = val;
          this.setColor();
          this.createQrcode();
        }
      }
    },
    navCode(val) {
      console.log(val, "code");
      if (this.navCode === "visit") {
        if (this.visitAddrList.length !== 0) {
          this.isInvited = false;
          this.codeData = this.selectVisitAddr;
          this.setColor();
          this.createQrcode();
        } else {
          this.isInvited = true;
          this.codeColor = "gray";
        }
      } else {
        this.codeData = this.selectMyAddr;
        if (this.myAddrList.length !== 0) {
          this.setColor();
          this.createQrcode();
        } else if (this.noCheckAddr.length === 0) {
          // this.$refs.tostModal.open()
          this.checkUserState();
          // this.$toastModal()
          // Dialog.confirm({
          //   title: "欢迎你加入在线社区",
          //   message: "为了确保通行码正常使用,请完成实名认证流程，谢谢！",
          // })
          //   .then(() => {
          //     this.$router.push("/realNameAuthentication"); //去实名认证
          //   })
          //   .catch(() => {
          //     this.navCode = "visit";
          //   });
        }
      }
    }
  },
  mounted() {
    this.user = this.returnRoleData.userPO;
    if (this.user.certificateNum) {
      this.getJkm(); // 获取健康码
      this.initData();
    }
    this.timer = setInterval(this.showCurrentTime, 1000);

    this.$wx.ready(() => {
      console.log("wx-base-ready-----------------");
      console.log("nextTick---codemounted=-------------------");
      this.$wx.getLocation({
        type: "wgs84",
        success: res => {
          console.log("---------------------------------");
          console.log("getLocation", res);
          this.getDistrict(res.latitude, res.longitude);
        },
        fail: err => {
          console.log("err getLocation", err);
          this.visitAddrText = "杭州市西湖区绿城西溪国际";
        }
      });
    });

    this.$nextTick(() => {
      console.log(5454654654);
      if (this.navCode === "pass") {
        this.codeData = this.selectMyAddr;
        if (this.myAddrList.length !== 0) {
          this.setColor();
          this.createQrcode();
        } else if (this.noCheckAddr.length === 0) {
          setTimeout(() => {
            //   Dialog.confirm({
            //   title: "欢迎你加入在线社区",
            //   message: "为了确保通行码正常使用,请完成实名认证流程，谢谢！",
            // })
            //   .then(() => {
            //     this.$router.push("/realNameAuthentication"); //去实名认证
            //   })
            //   .catch(() => {
            //     this.navCode = "visit";
            //   });
            // this.toastVisible = true
            this.checkUserState();
          }, 500);
        }
      }
    });
  },
  methods: {
    checkUserState() {
      let state = 0; // 默认没有实名验证过
      let roleData = JSON.parse(localStorage.getItem("roleData"));

      if (roleData) {
        if (roleData.userPO.phone) {
          if (roleData.userPO.rlsbStatus == 1) {
            if (roleData.xiaoqu.length > 0) {
              var xiaoquArr = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.addrFlag === 1 &&
                  item.userZoneRelationPO.status === 2
              ); // 已通过并设置为默认房屋的数据
              var xiaoquCheck = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 2 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 审核通过并未注销的房屋
              var xiaoqu =
                xiaoquArr.length !== 0
                  ? xiaoquArr[0]
                  : xiaoquCheck.length !== 0
                    ? xiaoquCheck[0]
                    : null;
              var noCheck = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 1 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 有房屋但未审核
              var noCompleteZone = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 0 &&
                  item.userZoneRelationPO.cancel === 0
              ); // 有房屋但未完善
              var noPassZone = roleData.xiaoqu.filter(
                item =>
                  item.userZoneRelationPO.status === 3 ||
                  item.userZoneRelationPO.cancel === 1
              ); // 有房但未通过或已注销
              console.log(
                roleData,
                xiaoquCheck,
                xiaoqu,
                noCheck,
                noCompleteZone,
                noPassZone,
                "noPassZone"
              );
              if (xiaoqu) {
                state = 4; // 有住房且通过审核
              } else if (noCheck.length > 0) {
                state = 5; // 有住房但是未审核
              } else if (noCompleteZone.length > 0) {
                // 有房子但未完善
                state = 3;
              } else if (noPassZone.length > 0) {
                // 有房但未通过或已注销
                state = 3;
              }
            } else {
              state = 3; // 实名过没房子
            }
          } else {
            state = 2; // 未实名认证过
          }
        } else {
          state = 1; // 手机未验证过
        }
      }
      console.log(state, "state");
      if (state > 3) {
        return false;
      } else if (state == 3) {
        this.$refs.tostModal.open("暂未绑定房屋，绑定后即可开通所有服务。", 2);
        return false;
      } else {
        this.$refs.tostModal.open();
      }
    },
    cancelZ() {
      this.navCode = "visit";
    },
    initData() {
      this.getVisitList(); // 获取可用访客
      this.passDataList();
    },
    // 获取定位详址
    getDistrict(lat, lon) {
      let data = {
        location: `${lat},${lon}`,
        key: "ILYBZ-7ZMK2-RPMU3-CSPUT-62L5T-LNFX5",
        output: "jsonp"
      };
      this.$jsonp(`https://apis.map.qq.com/ws/geocoder/v1`, data)
        .then(res => {
          console.log("getLocationAddr", res);
          if (res.result) {
            if (this.visitAddrText === "") {
              this.visitAddrText =
                res.result.address || "杭州市西湖区绿城西溪国际";
            }
          }
        })
        .catch(err => {
          console.log("err getLocationAddr", err);
          if (this.visitAddrText === "") {
            this.visitAddrText = "杭州市西湖区绿城西溪国际";
          }
        });
    },
    // 获取通行码的数据
    passDataList() {
      let danwei = this.returnRoleData.danwei;
      let xiaoqus = this.returnRoleData.xiaoqu;
      var xiaoquFlag = xiaoqus.filter(
        item =>
          item.userZoneRelationPO.addrFlag === 1 &&
          item.userZoneRelationPO.status === 2
      ); // 设置了默认地址
      var xiaoquCheck = xiaoqus.filter(
        item =>
          item.userZoneRelationPO.status === 2 &&
          item.userZoneRelationPO.cancel === 0
      );
      this.noCheckAddr = xiaoqus.filter(
        item => item.userZoneRelationPO.status === 1
      ); // 未审核通过的家地址
      var xiaoqu;
      if (this.$route.query.type === "pass" && this.zoneId) {
        // 扫自己小区访客码进入自己的通行码
        var zoneXiaoqu = xiaoqus.filter(
          item =>
            item.userZoneRelationPO.zoneId === this.zoneId &&
            item.userZoneRelationPO.status === 2
        );
        if (zoneXiaoqu.length !== 0) {
          var flagZone = zoneXiaoqu.filter(
            item => item.userZoneRelationPO.addrFlag === 1
          );
          xiaoqu = flagZone.length !== 0 ? flagZone[0] : zoneXiaoqu[0];
        } else if (
          danwei.length > 0 &&
          danwei[0].userZoneRelationPO.zoneId === this.zoneId
        ) {
        } else {
          this.navCode = "visit"; // 不存在进入访客码申请带zoneId
          // uni.navigateTo({
          // 	url: `../../house-owner/visitor-temp/visitor-temp?zoneId=${id}`
          // })
        }
      } else {
        xiaoqu =
          xiaoquFlag.length !== 0
            ? xiaoquFlag[0]
            : xiaoquCheck.length !== 0
              ? xiaoquCheck[0]
              : null;
      }
      if (xiaoqu) {
        this.selectMyAddr = xiaoqu;
      } else if (danwei.length > 0) {
        this.selectMyAddr = danwei[0];
      } else {
        // 提示未设置房屋和单位信息
      }
      console.log("xiaoquCheck", xiaoquCheck);
      xiaoquCheck.forEach(item => {
        if (item.zonePO.checkStatus == "1") {
          this.myAddrList.push(item);
        }
      });
      danwei.forEach(item => {
        console.log("item", item);
        if (item.userZoneRelationPO.status === 1) {
          this.noCheckAddr.push(item); // 未审核通过的单位
        } else if (item.userZoneRelationPO.status === 2) {
          if (item.zonePO.checkStatus == "1") {
            this.myAddrList.push(item);
          }
        }
      });
      console.log(this.myAddrList, " this.myAddrList");
      if (this.myAddrList.length === 0 && this.noCheckAddr.length > 0) {
        this.selectMyAddr = this.noCheckAddr[0];
      }
    },
    /**
     * 获取用户信息
     */
    getUserInfo(id) {
      getUserInfo().then(res => {
        console.log(res);
        if (res.code === "SUCCESS") {
          this.user = res.data.userPO;
          let danwei = res.data.danwei;
          let xiaoqus = res.data.xiaoqu;
          var xiaoquFlag = xiaoqus.filter(
            item =>
              item.userZoneRelationPO.addrFlag === 1 &&
              item.userZoneRelationPO.status === 2
          ); // 设置了默认地址
          var xiaoquCheck = xiaoqus.filter(
            item =>
              item.userZoneRelationPO.status === 2 &&
              item.userZoneRelationPO.cancel === 0
          );
          var xiaoqu;
          if (id) {
            // 扫自己小区访客码进入自己的通行码
            var zoneXiaoqu = xiaoqus.filter(
              item =>
                item.userZoneRelationPO.zoneId === id &&
                item.userZoneRelationPO.status === 2
            );
            if (zoneXiaoqu.length !== 0) {
              var flagZone = zoneXiaoqu.filter(
                item => item.userZoneRelationPO.addrFlag === 1
              );
              xiaoqu = flagZone.length !== 0 ? flagZone[0] : zoneXiaoqu[0];
            } else if (
              danwei.length > 0 &&
              danwei[0].userZoneRelationPO.zoneId === id
            ) {
            } else {
              this.navCode = "visit"; // 不存在进入访客码
              // uni.navigateTo({
              // 	url: `../../house-owner/visitor-temp/visitor-temp?zoneId=${id}`
              // })
            }
          } else {
            xiaoqu =
              xiaoquFlag.length !== 0
                ? xiaoquFlag[0]
                : xiaoquCheck.length !== 0
                  ? xiaoquCheck[0]
                  : null;
          }
          if (xiaoqu) {
            this.selectMyAddr = xiaoqu;
          } else if (danwei.length > 0) {
            this.selectMyAddr = danwei[0];
          } else {
          }
          xiaoquCheck.forEach(item => {
            this.myAddrList.push(item);
          });
          danwei.forEach(item => {
            this.myAddrList.push(item);
          });
        }
      });
    },
    /**
     * 获取可以用的访客码
     */
    getVisitList: function () {
      getVisitList().then(res => {
        if (res.code === "SUCCESS") {
          if (res.data.length !== 0) {
            this.isInvited = false;
            res.data.forEach(item => {
              let nowDate = new Date(item.systemTime).getTime();
              let endDate = new Date(item.visitorRecordPO.endTime).getTime();
              item.isPastCode = endDate < nowDate;
            });
            this.visitAddrList = res.data.filter(item => !item.isPastCode);
            if (!this.visitId) {
              this.selectVisitAddr = this.visitAddrList[0];
            } else {
              this.selectVisitAddr =
                this.visitAddrList.filter(
                  item => item.visitorRecordPO.id == this.visitId
                )[0] || {};
            }
          }
        }
      });
    },
    // 获取健康码
    getJkm: function () {
      getJkm({ idCard: this.user.certificateNum }).then(res => {
        if (res.code == "SUCCESS") {
          let data = res.data;
          if (data.code === 1) {
            this.jkmStr = data.data;
            this.setColor();
          }
        }
        // this.initData();
      });
    },
    // 设置颜色
    setColor() {
      if (this.jkmStr === "") {
        this.codeColor = "green";
      } else if (this.jkmStr === "绿码") {
        this.codeColor = "green";
      } else if (this.jkmStr === "黄码") {
        this.codeColor = "orange";
      } else {
        this.codeColor = "red";
      }
    },
    // 前往实名认证
    goRealName() {
      this.$router.push("/realNameAuthentication");
    },
    /**
     * 选择nav
     */
    clickNav(item) {
      this.navCode = item.value;
    },
    // 显示地址选择
    showAddr() {
      if (this.navCode === "pass") {
        this.showMyAddrDialog = true;
      } else {
        if (this.visitAddrList.length > 0) {
          this.showVisitAddrDialog = true;
        }
      }
    },
    // 通行码地址选择确定
    confirmMyAddr(val) {
      this.showMyAddrDialog = false;
      this.selectMyAddr = this.myAddrList.filter(
        item => item.userZoneRelationPO.id === val
      )[0];
    },
    // 访客码选择地址确定
    confirmVisitAddr(val) {
      this.showVisitAddrDialog = false;
      this.selectVisitAddr = this.visitAddrList.filter(
        item => item.visitorRecordPO.id === val
      )[0];
    },
    /**
     * 获取当前时间
     */
    showCurrentTime() {
      this.currentTime = this.$tools.formatDate(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      );
    },
    /**
     * 生成二维码
     */
    createQrcode() {
      console.log("createQrcode", this.codeColor, this.codeData, this.navCode);
      if (!(this.isInvited && this.navCode === "visit")) {
        // 只要不是访客码申请情况都可生成二维码
        let currentUrl;
        if (
          this.codeData.zonePO.ladderControlFlag &&
          this.codeData.zonePO.ladderControlFlag === 1
        ) {
          let pass = "9080707501";
          let t = parseInt(new Date().getTime() / 1000); // 时间戳
          let f = this.codeData.userZoneRelationPO.floor; // 楼层
          let r = this.codeData.userZoneRelationPO.chamber; // 房间号
          let e = "30"; // 有效持续时间 (分钟)
          let cc = f + t + e + r + pass;
          let c = this.$tools.upperCase(md5(cc).substr(8, 16)); // md5加密16位大写
          currentUrl = `http://${window.location.host}/f/1080299755566796801?f=${f}&t=${t}&e=${e}&r=${r}&c=${c}`;
          console.log("codeValue", f, t, e, r, cc, c, currentUrl);
        } else {
          currentUrl = window.location.href;
        }
        this.$refs.qrcode.innerHTML = "";
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: currentUrl,
          width: 157,
          height: 157,
          colorDark: this.colorList[this.codeColor],
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.H
        });
        this.$refs.bigQrcode.innerHTML = "";
        let bigQrcode = new QRCode(this.$refs.bigQrcode, {
          text: currentUrl,
          width: 280,
          height: 280,
          colorDark: this.colorList[this.codeColor],
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.H
        });
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="less" scoped>
.code {
  position: relative;
  height: 100%;
  font-size: 0.746667rem /* 14/18.75 */;
  background: #fff;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .person {
    display: flex;
    height: 9.92rem /* 186/18.75 */;
    padding: 1.066667rem /* 20/18.75 */ 1.066667rem /* 20/18.75 */ 3.2rem
      /* 60/18.75 */;
    color: #fff;
    &.person-mt {
      padding-top: 1.6rem /* 30/18.75 */;
    }
    .avatar {
      width: 3.52rem /* 66/18.75 */;
      height: 3.52rem /* 66/18.75 */;
      border-radius: 50%;
      margin-right: 0.853333rem /* 16/18.75 */;
    }
    .name {
      font-size: 1.493333rem /* 28/18.75 */;
    }
    .name.name-mt {
      margin-top: 0.8rem /* 15/18.75 */;
    }
    .addr {
      margin-top: 0.266667rem /* 5/18.75 */;
      &.addr-mt {
        margin-top: 0.533333rem /* 10/18.75 */;
      }
      img {
        width: 0.72rem /* 13.5/18.75 */;
        height: 0.8rem /* 15/18.75 */;
        vertical-align: middle;
        margin-right: 0.24rem /* 4.5/18.75 */;
      }
      .addr-name {
        display: inline-block;
        vertical-align: middle;
        max-width: 10.666667rem /* 200/18.75 */;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .arrow {
        vertical-align: middle;
        margin-left: 0.32rem /* 6/18.75 */;
      }
    }
    .tag-box {
      display: flex;
      margin-top: 0.266667rem /* 5/18.75 */;
      .tag {
        padding: 0.16rem /* 3/18.75 */ 0.426667rem /* 8/18.75 */;
        background: rgba(225, 225, 225, 0.44);
        margin-right: 0.373333rem /* 7/18.75 */;
        border-radius: 0.266667rem; /* 5/18.75 */
      }
    }
  }
  .box {
    position: absolute;
    width: 18.133333rem /* 340/18.75 */;
    height: 24.746667rem /* 464/18.75 */;
    top: 6.613333rem /* 124/18.75 */;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #fff;
    border-radius: 0.8rem /* 15/18.75 */;
    box-shadow: 0.106667rem /* 2/18.75 */ 0 0.533333rem /* 10/18.75 */
      rgba(4, 0, 0, 0.1);
    .code-nav {
      display: flex;
      text-align: center;
      font-size: 0.96rem /* 18/18.75 */;
      height: 3.306667rem /* 62/18.75 */;
      line-height: 3.306667rem /* 62/18.75 */;
      border-bottom: 0.026667rem /* 0.5/18.75 */ solid #ebebeb;
      div {
        position: relative;
        flex: 1;
        color: #999;
        &:first-child::after {
          content: '';
          position: absolute;
          width: 0.026667rem /* 0.5/18.75 */;
          height: 1.146667rem /* 21.5/18.75 */;
          background: #ebebeb;
          right: 0;
          top: 1.066667rem /* 20/18.75 */;
        }
        &.active {
          color: #2b71fd;
        }
      }
    }
    .code-content {
      text-align: center;
      padding-top: 1.333333rem /* 25/18.75 */;
      .time {
        width: 12rem /* 225/18.75 */;
        margin: 0 auto;
        font-size: 2.133333rem /* 40/18.75 */;
        // font-weight: bold;
        line-height: 1.3;
        color: #333;
      }
      .code-img {
        width: 157px;
        height: 157px;
        margin: 0.8rem /* 15/18.75 */ auto 1.333333rem /* 25/18.75 */;
      }
      .hide {
        width: 14.826667rem /* 278/18.75 */;
        margin: 0 auto;
        color: #666;
        line-height: 1.8;
      }
    }
    .none-code {
      text-align: center;
      .pend-check {
        font-size: 0.906667rem /* 17/18.75 */;
        color: #666;
        img {
          width: 11.36rem /* 213/18.75 */;
          height: 11.36rem /* 213/18.75 */;
          margin: 2.666667rem /* 50/18.75 */ 0;
        }
      }
    }
  }
  &.green {
    .person {
      background-color: #27ad64;
    }
  }
  &.red {
    .person {
      background-color: #de4040;
    }
  }
  &.orange {
    .person {
      background-color: #db7e23;
    }
  }
  &.gray {
    .person {
      background-color: #2b71fd;
    }
  }
  .modle {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    .big-code-box {
      width: 300px;
      height: 300px;
      margin: 50% auto;
      background: #fff;
      overflow: hidden;
    }
    .big-code-img {
      width: 280px;
      height: 280px;
      margin: 10px auto;
    }
  }
}
.addr-list {
  max-height: 11.733333rem /* 220/18.75 */;
  overflow-y: auto;
  padding: 1.066667rem /* 20/18.75 */ 0.8rem /* 15/18.75 */ 1.066667rem
    /* 20/18.75 */ 1.066667rem /* 20/18.75 */;
  .addr-radio {
    height: 3.2rem /* 60/18.75 */;
  }
  .radio-label {
    color: #aaa;
  }
  .check-label {
    color: #333;
  }
}
.modle {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  .big-code-img {
    width: 300px;
    height: 300px;
    margin: 50% auto;
  }
}
</style>
